<template>
  <div class="container">
    <div class="d-flex align-items-center py-2">
      <button
        type="button"
        class="nav-link me-3"
        @click="this.$router.push(`/project/${projectId}`)"
      >
        <font-awesome-icon :icon="['fas', 'arrow-left']" class="nav-icon" />
        上一頁
      </button>
      <p class="mb-0">
        計畫管理
        <font-awesome-icon :icon="['fas', 'angle-double-right']" class="ms-1 me-1" />
        {{ project.name }}
        <font-awesome-icon :icon="['fas', 'angle-double-right']" class="ms-1 me-1" />
        {{ caseObj.name }}
      </p>
    </div>
    <div class="jo-title mb-3 py-3">
      <h2 class="text-center m-0">個案資訊</h2>
    </div>
    <div class="row text-center fs-4">
      <div class="col-sm-2 mb-3">編號：{{ caseObj.sn }}</div>
      <div class="col-sm-2 mb-3">姓名：{{ caseObj.name }}</div>
      <div class="col-sm-3 mb-3">生日：{{ caseBirthday }}</div>
      <div class="col-sm-2 mb-3">年齡：{{ caseAge }}</div>
      <div class="col-sm-3 mb-3">生理性別：{{ caseGender }}</div>
      <div class="col-sm-3 mb-3">建立者：{{ creater.name }}</div>
    </div>
    <div class="jo-title mb-3 py-3">
      <h2 class="text-center m-0">量表填寫記錄</h2>
    </div>
    <div class="row">
      <div class="col-sm-3 mb-4">
        <ul class="list-group text-center">
          <li class="survey-list-first list-group-item">個案基本資料</li>
          <li
            class="survey-list-item list-group-item"
            v-for="(survey, index) in basicInfoList"
            :key="'basicinfo_' + index"
            @click="displaySurvey('basicInfo', index)"
          >
            {{ getDateString(survey.createdAt) }}
          </li>
          <li v-if="!basicInfoList.length" class="list-group-item">＜尚未填寫過＞</li>
        </ul>
      </div>
      <div class="col-sm-3 mb-4">
        <ul class="list-group text-center">
          <li class="survey-list-first list-group-item">吞嚥能力評估工具</li>
          <li
            class="survey-list-item list-group-item"
            v-for="(survey, index) in eat10List"
            :key="'eat10_' + index"
            @click="displaySurvey('eat10', index)"
          >
            {{ getDateString(survey.createdAt) }}
          </li>
          <li v-if="!eat10List.length" class="list-group-item">＜尚未填寫過＞</li>
        </ul>
      </div>
      <div class="col-sm-3 mb-4">
        <ul class="list-group text-center">
          <li class="survey-list-first list-group-item">功能性由口進食量表</li>
          <li
            class="survey-list-item list-group-item"
            v-for="(survey, index) in foisList"
            :key="'fois_' + index"
            @click="displaySurvey('fois', index)"
          >
            {{ getDateString(survey.createdAt) }}
          </li>
          <li v-if="!foisList.length" class="list-group-item">＜尚未填寫過＞</li>
        </ul>
      </div>
      <div class="col-sm-3 mb-4">
        <ul class="list-group text-center">
          <li class="survey-list-first list-group-item">迷你營養評估量表</li>
          <li
            class="survey-list-item list-group-item"
            v-for="(survey, index) in mnasfList"
            :key="'mnasf_' + index"
            @click="displaySurvey('mnasf', index)"
          >
            {{ getDateString(survey.createdAt) }}
          </li>
          <li v-if="!mnasfList.length" class="list-group-item">＜尚未填寫過＞</li>
        </ul>
      </div>
      <div class="col-sm-3 mb-4">
        <ul class="list-group text-center">
          <li class="survey-list-first list-group-item">身心健康狀況量表</li>
          <li
            class="survey-list-item list-group-item"
            v-for="(survey, index) in sf36List"
            :key="'sf36_' + index"
            @click="displaySurvey('sf36', index)"
          >
            {{ getDateString(survey.createdAt) }}
          </li>
          <li v-if="!sf36List.length" class="list-group-item">＜尚未填寫過＞</li>
        </ul>
      </div>
      <div class="col-sm-3 mb-4">
        <ul class="list-group text-center">
          <li class="survey-list-first list-group-item">SARC-F 量表</li>
          <li
            class="survey-list-item list-group-item"
            v-for="(survey, index) in sarcfList"
            :key="'sarcf_' + index"
            @click="displaySurvey('sarcf', index)"
          >
            {{ getDateString(survey.createdAt) }}
          </li>
          <li v-if="!sarcfList.length" class="list-group-item">＜尚未填寫過＞</li>
        </ul>
      </div>
      <div class="col-sm-3 mb-4">
        <ul class="list-group text-center">
          <li class="survey-list-first list-group-item">AD8 極早期失智症篩檢量表</li>
          <li
            class="survey-list-item list-group-item"
            v-for="(survey, index) in ad8List"
            :key="'ad8_' + index"
            @click="displaySurvey('ad8', index)"
          >
            {{ getDateString(survey.createdAt) }}
          </li>
          <li v-if="!ad8List.length" class="list-group-item">＜尚未填寫過＞</li>
        </ul>
      </div>
      <div class="col-sm-3 mb-4">
        <ul class="list-group text-center">
          <li class="survey-list-first list-group-item">臨床失智評估量表</li>
          <li
            class="survey-list-item list-group-item"
            v-for="(survey, index) in cdrList"
            :key="'cdr_' + index"
            @click="displaySurvey('cdr', index)"
          >
            {{ getDateString(survey.createdAt) }}
          </li>
          <li v-if="!cdrList.length" class="list-group-item">＜尚未填寫過＞</li>
        </ul>
      </div>
      <div class="col-sm-3 mb-4">
        <ul class="list-group text-center">
          <li class="survey-list-first list-group-item">簡易心智量表</li>
          <li
            class="survey-list-item list-group-item"
            v-for="(survey, index) in mmseList"
            :key="'mmse_' + index"
            @click="displaySurvey('mmse', index)"
          >
            {{ getDateString(survey.createdAt) }}
          </li>
          <li v-if="!mmseList.length" class="list-group-item">＜尚未填寫過＞</li>
        </ul>
      </div>
    </div>
    <!-- 新增計畫 Modal -->
    <div
      class="modal fade"
      id="displaySurveyModal"
      ref="displaySurveyModal"
      tabindex="-1"
      aria-labelledby="displaySurveyModal"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="displaySurveyModal">
              {{ getSurveyName(displayingSurveyType) }}
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <DisplaySurvey
              v-if="displayingSurveyType"
              :surveyType="displayingSurveyType"
              :surveyData="displayingSurveyData"
            ></DisplaySurvey>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">關閉</button>
            <button
              type="button"
              class="btn btn-danger"
              @click="triggerDelSurvey"
              v-show="userCharacter != 'share'"
            >
              刪除
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 載入 firestore
// eslint-disable-next-line object-curly-newline
import { doc, getDoc, getDocs, deleteDoc, query, collection, where } from 'firebase/firestore';
// 載入 lodash
import _ from 'lodash';
// 載入 Modal
import { Modal } from 'bootstrap';
import { db } from '@/assets/firebase';
import DisplaySurvey from '@/components/DisplaySurvey.vue';

export default {
  components: {
    DisplaySurvey,
  },
  props: {
    userUid: String,
  },
  data() {
    return {
      projectId: this.$route.params.projectId,
      caseId: this.$route.params.caseId,
      project: {},
      caseObj: {},
      creater: {},
      userCharacter: '',
      // 量表
      basicInfoList: [],
      eat10List: [],
      foisList: [],
      mnasfList: [],
      sf36List: [],
      sarcfList: [],
      ad8List: [],
      cdrList: [],
      mmseList: [],
      // 現正顯示之量表
      displayingSurveyType: '',
      displayingSurveyIndex: 0,
      displayingSurveyData: {},
      // Modal
      displaySurveyModal: null,
    };
  },
  methods: {
    async getProject() {
      const loader = this.$loading.show();
      try {
        const docRef = doc(db, 'projects', this.projectId);
        const docSnap = await getDoc(docRef);
        // eslint-disable-next-line no-param-reassign
        this.project = _.cloneDeep(docSnap.data());
        // 檢查使用者的角色
        this.checkCurrentUserCharacter();
        loader.hide();
      } catch (e) {
        loader.hide();
        this.$swal({
          text: String(e),
          icon: 'error',
        });
      }
    },
    async getCase() {
      const loader = this.$loading.show();
      try {
        // 取得所有該計畫的個案
        const docRef = doc(db, 'projects', this.projectId, 'cases', this.caseId);
        const docSnap = await getDoc(docRef);
        // eslint-disable-next-line no-param-reassign
        this.caseObj = _.cloneDeep(docSnap.data());
        // 取得建立者的資料
        this.getCreater();
        loader.hide();
      } catch (e) {
        loader.hide();
        this.$swal({
          text: String(e),
          icon: 'error',
        });
      }
    },
    async getCreater() {
      const loader = this.$loading.show();
      try {
        // 取得所有該計畫的個案
        const docRef = doc(db, 'users', this.caseObj.creater);
        const docSnap = await getDoc(docRef);
        // eslint-disable-next-line no-param-reassign
        this.creater = _.cloneDeep(docSnap.data());
        loader.hide();
      } catch (e) {
        loader.hide();
        this.$swal({
          text: String(e),
          icon: 'error',
        });
      }
    },
    async getBasicInfo() {
      try {
        const q = query(collection(db, 'basicinfo'), where('caseId', '==', this.caseId));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((survey) => {
          const surveyData = _.cloneDeep(survey.data());
          surveyData.id = survey.id;
          this.basicInfoList.push(surveyData);
        });
        this.basicInfoList.sort((a, b) => {
          if (a.createdAt < b.createdAt) {
            return 1;
          }
          if (a.createdAt > b.createdAt) {
            return -1;
          }
          return 0;
        });
      } catch (e) {
        this.$swal({
          text: String(e),
          icon: 'error',
        });
      }
    },
    async getEat10() {
      try {
        const q = query(collection(db, 'eat10'), where('caseId', '==', this.caseId));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((survey) => {
          const surveyData = _.cloneDeep(survey.data());
          surveyData.id = survey.id;
          this.eat10List.push(surveyData);
        });
        this.eat10List.sort((a, b) => {
          if (a.createdAt < b.createdAt) {
            return 1;
          }
          if (a.createdAt > b.createdAt) {
            return -1;
          }
          return 0;
        });
      } catch (e) {
        this.$swal({
          text: String(e),
          icon: 'error',
        });
      }
    },
    async getFois() {
      try {
        const q = query(collection(db, 'fois'), where('caseId', '==', this.caseId));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((survey) => {
          const surveyData = _.cloneDeep(survey.data());
          surveyData.id = survey.id;
          this.foisList.push(surveyData);
        });
        this.foisList.sort((a, b) => {
          if (a.createdAt < b.createdAt) {
            return 1;
          }
          if (a.createdAt > b.createdAt) {
            return -1;
          }
          return 0;
        });
      } catch (e) {
        this.$swal({
          text: String(e),
          icon: 'error',
        });
      }
    },
    async getMnasf() {
      try {
        const q = query(collection(db, 'mnasf'), where('caseId', '==', this.caseId));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((survey) => {
          const surveyData = _.cloneDeep(survey.data());
          surveyData.id = survey.id;
          this.mnasfList.push(surveyData);
        });
        this.mnasfList.sort((a, b) => {
          if (a.createdAt < b.createdAt) {
            return 1;
          }
          if (a.createdAt > b.createdAt) {
            return -1;
          }
          return 0;
        });
      } catch (e) {
        this.$swal({
          text: String(e),
          icon: 'error',
        });
      }
    },
    async getSF36() {
      try {
        const q = query(collection(db, 'sf36'), where('caseId', '==', this.caseId));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((survey) => {
          const surveyData = _.cloneDeep(survey.data());
          surveyData.id = survey.id;
          this.sf36List.push(surveyData);
        });
        this.sf36List.sort((a, b) => {
          if (a.createdAt < b.createdAt) {
            return 1;
          }
          if (a.createdAt > b.createdAt) {
            return -1;
          }
          return 0;
        });
      } catch (e) {
        this.$swal({
          text: String(e),
          icon: 'error',
        });
      }
    },
    async getSarcf() {
      try {
        const q = query(collection(db, 'sarcf'), where('caseId', '==', this.caseId));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((survey) => {
          const surveyData = _.cloneDeep(survey.data());
          surveyData.id = survey.id;
          this.sarcfList.push(surveyData);
        });
        this.sarcfList.sort((a, b) => {
          if (a.createdAt < b.createdAt) {
            return 1;
          }
          if (a.createdAt > b.createdAt) {
            return -1;
          }
          return 0;
        });
      } catch (e) {
        this.$swal({
          text: String(e),
          icon: 'error',
        });
      }
    },
    async getAd8() {
      try {
        const q = query(collection(db, 'ad8'), where('caseId', '==', this.caseId));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((survey) => {
          const surveyData = _.cloneDeep(survey.data());
          surveyData.id = survey.id;
          this.ad8List.push(surveyData);
        });
        this.ad8List.sort((a, b) => a.createdAt > b.createdAt);
      } catch (e) {
        this.$swal({
          text: String(e),
          icon: 'error',
        });
      }
    },
    async getCdr() {
      try {
        const q = query(collection(db, 'cdr'), where('caseId', '==', this.caseId));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((survey) => {
          const surveyData = _.cloneDeep(survey.data());
          surveyData.id = survey.id;
          this.cdrList.push(surveyData);
        });
        this.cdrList.sort((a, b) => a.createdAt > b.createdAt);
      } catch (e) {
        this.$swal({
          text: String(e),
          icon: 'error',
        });
      }
    },
    async getMmse() {
      try {
        const q = query(collection(db, 'mmse'), where('caseId', '==', this.caseId));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((survey) => {
          const surveyData = _.cloneDeep(survey.data());
          surveyData.id = survey.id;
          this.mmseList.push(surveyData);
        });
        this.mmseList.sort((a, b) => a.createdAt > b.createdAt);
      } catch (e) {
        this.$swal({
          text: String(e),
          icon: 'error',
        });
      }
    },
    async deleteSurvey() {
      const loader = this.$loading.show();
      try {
        const displayListName = `${this.displayingSurveyType}List`;
        const surveyId = this[displayListName][this.displayingSurveyIndex].id;
        await deleteDoc(doc(db, this.displayingSurveyType, surveyId));
        this[displayListName].splice(this.displayingSurveyIndex, 1);
        // 清空「正被選取」相關的變數
        this.displayingSurveyData = null;
        this.displayingSurveyIndex = null;
        this.displayingSurveyType = '';
        loader.hide();
        this.$swal({
          text: '刪除成功',
          icon: 'success',
        });
      } catch (e) {
        loader.hide();
        this.$swal({
          text: String(e),
          icon: 'error',
        });
      }
    },
    displaySurvey(type, index) {
      this.displayingSurveyType = type;
      this.displayingSurveyIndex = index;
      this.displayingSurveyData = _.cloneDeep(this[`${type}List`][index]);
      this.displaySurveyModal.show();
    },
    checkCurrentUserCharacter() {
      if (this.project.admin === this.userUid) {
        this.userCharacter = 'admin';
      } else if (this.project.coAdmin.indexOf(this.userUid) !== -1) {
        this.userCharacter = 'coAdmin';
      } else if (this.project.coop.indexOf(this.userUid) !== -1) {
        this.userCharacter = 'coop';
      } else if (this.project.share.indexOf(this.userUid) !== -1) {
        this.userCharacter = 'share';
      } else {
        this.$router.push('/projects');
      }
    },
    republicDateTranslate(republicDate) {
      const republicYear = Number(republicDate.slice(0, 3));
      const month = Number(republicDate.slice(3, 5));
      const day = Number(republicDate.slice(5, 7));
      const date = new Date();
      date.setFullYear(republicYear + 1911);
      date.setMonth(month - 1);
      date.setDate(day);
      return date;
    },
    getDateString(timestamp) {
      const date = new Date(timestamp);
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      let hour = date.getHours();
      let minute = date.getMinutes();
      if (month < 10) {
        month = `0${month}`;
      }
      if (day < 10) {
        day = `0${day}`;
      }
      if (hour < 10) {
        hour = `0${hour}`;
      }
      if (minute < 10) {
        minute = `0${minute}`;
      }
      // 檢測是否為 NaN
      if (Number.isNaN(year)) {
        year = '0000';
      }
      if (Number.isNaN(month)) {
        month = '00';
      }
      if (Number.isNaN(day)) {
        day = '00';
      }
      if (Number.isNaN(hour)) {
        hour = '00';
      }
      if (Number.isNaN(minute)) {
        minute = '00';
      }
      return `${year}年${month}月${day}日 ${hour}:${minute}`;
    },
    getSurveyName(type) {
      switch (type) {
        case 'basicInfo':
          return '個案基本資料';
        case 'eat10':
          return '吞嚥能力評估工具';
        case 'fois':
          return '功能性由口進食量表';
        case 'mnasf':
          return '迷你營養評估量表';
        case 'sf36':
          return '身心健康狀況量表';
        case 'sarcf':
          return 'SARC-F 量表';
        case 'ad8':
          return 'AD8 極早期失智症篩檢量表';
        case 'cdr':
          return '臨床失智評估量表';
        case 'mmse':
          return '簡易心智量表';
        default:
          return '';
      }
    },
    triggerDelSurvey() {
      this.$swal({
        icon: 'warning',
        text: '請問確定刪除此份量表嗎？',
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: '確定',
        cancelButtonText: '取消',
      }).then(({ isConfirmed }) => {
        if (isConfirmed) {
          this.displaySurveyModal.hide();
          return this.deleteSurvey();
        }
        return false;
      });
    },
  },
  mounted() {
    // 讀取計畫資料
    this.getProject();
    // 讀取個案資料
    this.getCase();
    // 讀取所有量表資料
    this.getBasicInfo();
    this.getEat10();
    this.getFois();
    this.getMnasf();
    this.getSF36();
    this.getSarcf();
    this.getAd8();
    this.getCdr();
    this.getMmse();
    // Get Modal Element
    this.displaySurveyModal = new Modal(this.$refs.displaySurveyModal);
  },
  computed: {
    // 計算個案的年紀
    caseAge() {
      const birthYear = new Date(this.caseObj.birthday).getFullYear();
      const birthMonth = new Date(this.caseObj.birthday).getMonth() + 1;
      const birthDate = new Date(this.caseObj.birthday).getDate();
      const nowYear = new Date().getFullYear();
      const nowMonth = new Date().getMonth() + 1;
      const nowDate = new Date().getDate();
      let age = nowYear - birthYear;
      if (nowMonth - birthMonth < 0 || (nowMonth - birthMonth === 0 && nowDate < birthDate)) {
        age -= 1;
      }
      return age || '';
    },
    // 輸出顯示性別
    caseGender() {
      if (this.caseObj.gender === 'male') {
        return '男性';
      }
      if (this.caseObj.gender === 'female') {
        return '女性';
      }
      return '';
    },
    // 輸出顯示生日
    caseBirthday() {
      const birthYear = new Date(this.caseObj.birthday).getFullYear();
      let birthMonth = new Date(this.caseObj.birthday).getMonth() + 1;
      let birthDate = new Date(this.caseObj.birthday).getDate();
      if (birthMonth < 10) {
        birthMonth = `0${birthMonth}`;
      }
      if (birthDate < 10) {
        birthDate = `0${birthDate}`;
      }
      return `${birthYear || ''}年 ${birthMonth || ''}月 ${birthDate || ''}日`;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  color: $primary;
}
.nav-link {
  color: $primary;
  font-size: 1rem;
  border-radius: 0.5rem;
  margin-right: 0.5rem;
  cursor: pointer;
  border: none;
  background-color: $bright;
  .nav-icon {
    color: $primary;
    font-size: 1rem;
    margin-bottom: -5px;
    margin: 0 0.5rem 0 0;
  }
  &:hover {
    background-color: $primary;
    text-decoration: none;
    color: white;
    .nav-icon {
      color: white;
    }
  }
}
.jo-title {
  border-top: 2px solid $primary;
  border-bottom: 2px solid $primary;
}
.list-group-item {
  color: $primary;
}
.survey-list-first {
  background-color: $primary;
  color: white;
}
.survey-list-item {
  cursor: pointer;
  &:hover {
    background-color: $primary;
    color: white;
  }
}
</style>
