<template>
  <div>
    <div class="col-sm-12">建立日期：{{ getDateString(surveyData.createdAt) }}</div>
    <div class="col-sm-12 mb-2">更新日期：{{ getDateString(surveyData.updatedAt) }}</div>
    <div v-if="surveyType === 'basicInfo'" class="row fs-6">
      <div class="col-sm-6">
        身高：<span class="ans">{{ surveyData.height }}</span> 公分
      </div>
      <div class="col-sm-6">
        體重：<span class="ans">{{ surveyData.weight }}</span> 公斤
      </div>
      <div class="col-sm-6">
        BMI：<span class="ans">{{ surveyData.bmi }}</span>
      </div>
      <div class="col-sm-6">
        腰圍：<span class="ans">{{ surveyData.waistline }}</span> 公分
      </div>
      <div class="col-sm-6">
        活動量：<span class="ans">{{ getActivityString(surveyData.activity) }}</span>
      </div>
      <div class="col-sm-6">
        每日熱量需求：<span class="ans">{{ surveyData.calories }}</span> 大卡
      </div>
      <div class="col-sm-6">
        體脂肪：<span class="ans">{{ surveyData.fat }}</span> %
      </div>
      <div class="col-sm-6">
        肌肉量：<span class="ans">{{ surveyData.muscle }}</span> 公斤
      </div>
      <div class="col-sm-12">
        三頭肌皮下脂肪厚度：<span class="ans">{{ surveyData.tsf }}</span> 公釐
      </div>
      <div class="col-sm-6">
        收縮壓：<span class="ans">{{ surveyData.sbp }}</span> mmHg
      </div>
      <div class="col-sm-6">
        舒張壓：<span class="ans">{{ surveyData.dbp }}</span> mmHg
      </div>
      <div class="col-sm-12 mt-2">
        備註：<br />
        {{ surveyData.note }}
      </div>
    </div>
    <div v-if="surveyType === 'eat10'" class="row fs-6">
      <div class="col-12 mb-2">沒有問題 1 ~ 5 嚴重問題</div>
      <div class="col-12">
        1. 吞嚥問題讓我體重減輕：<span class="ans">{{ surveyData.q1 }}</span>
      </div>
      <div class="col-12">
        2. 吞嚥問題讓我不能像以前一樣外出用餐：<span class="ans">{{ surveyData.q2 }}</span>
      </div>
      <div class="col-12">
        3. 喝飲料時要花很多力氣：<span class="ans">{{ surveyData.q3 }}</span>
      </div>
      <div class="col-12">
        4. 吃固體食物時要花很多力氣：<span class="ans">{{ surveyData.q4 }}</span>
      </div>
      <div class="col-12">
        5.吞藥丸時要花很多力氣：<span class="ans">{{ surveyData.q5 }}</span>
      </div>
      <div class="col-12">
        6. 吞嚥的過程會引起疼痛：<span class="ans">{{ surveyData.q6 }}</span>
      </div>
      <div class="col-12">
        7. 吞嚥問題讓我無法享受用餐：<span class="ans">{{ surveyData.q7 }}</span>
      </div>
      <div class="col-12">
        8. 進食後會感到有東西黏在喉嚨上：<span class="ans">{{ surveyData.q8 }}</span>
      </div>
      <div class="col-12">
        9. 進食的時候會咳嗽：<span class="ans">{{ surveyData.q9 }}</span>
      </div>
      <div class="col-12">
        10. 吞嚥的過程讓我感到有壓力：<span class="ans">{{ surveyData.q10 }}</span>
      </div>
    </div>
    <div v-if="surveyType === 'fois'" class="row fs-6">
      <div class="col-12">
        <span class="ans">{{ getFoisString(surveyData.level) }}</span>
      </div>
    </div>
    <div v-if="surveyType === 'mnasf'" class="row fs-6">
      <div class="col-12">
        1. 過去三個月內有沒有因為食慾不振、消化問題、咀嚼或吞嚥困難而造成減少食量：
        <span class="ans">{{ getMnasfString(1, surveyData.q1) }}</span>
      </div>
      <div class="col-12">
        2. 過去三個月體重下降的情形：
        <span class="ans">{{ getMnasfString(2, surveyData.q2) }}</span>
      </div>
      <div class="col-12">
        3. 活動能力：
        <span class="ans">{{ getMnasfString(3, surveyData.q3) }}</span>
      </div>
      <div class="col-12">
        4. 過去三個月內有沒有受到心理創傷或患上急性疾病：
        <span class="ans">{{ getMnasfString(4, surveyData.q4) }}</span>
      </div>
      <div class="col-12">
        5. 精神心理問題：
        <span class="ans">{{ getMnasfString(5, surveyData.q5) }}</span>
      </div>
      <div class="col-12">
        6-1. 身體質量指數(BMI)：
        <span class="ans">{{ getMnasfString(6, surveyData.q6_1) }}</span>
      </div>
      <div class="col-12">
        6-2. 小腿圍(CC)：
        <span class="ans">{{ getMnasfString(7, surveyData.q6_2) }}</span>
      </div>
      <div class="col-12 mt-2">
        總分：
        <span class="ans">{{ getMnasfString(8, surveyData.total) }}</span>
      </div>
    </div>
    <div v-if="surveyType === 'sf36'" class="row fs-6">
      <div class="ans col-12">（極好 1 ~ 5 不好）</div>
      <div class="col-12">
        {{ sf36QList[0].title }}<span class="ans">{{ `${surveyData.ans[0]}` }}</span>
      </div>
      <div class="ans col-12 border-top mt-1 pt-1">（好很多 1 ~ 5 差很多）</div>
      <div class="col-12">
        {{ sf36QList[1].title }}<span class="ans">{{ `${surveyData.ans[1]}` }}</span>
      </div>
      <div class="col-12 border-top mt-1 pt-1">{{ sf36QList[2].title }}</div>
      <div class="ans col-12 mb-1">（會，受到很多限制 1 ~ 3 不會，完全不受限制）</div>
      <template v-for="(q, index) in sf36QList[2].subQues" :key="'q3_' + index">
        <div class="col-12">
          {{ q }}<span class="ans">{{ `${surveyData.ans[2].path[index]}` }}</span>
        </div>
      </template>
      <div class="col-12 border-top mt-1 pt-1">{{ sf36QList[3].title }}</div>
      <div class="ans col-12 mb-1">（一直都是 1 ~ 5 從不）</div>
      <template v-for="(q, index) in sf36QList[3].subQues" :key="'q4_' + index">
        <div class="col-12">
          {{ q }}<span class="ans">{{ `${surveyData.ans[3].path[index]}` }}</span>
        </div>
      </template>
      <div class="col-12 border-top mt-1 pt-1">{{ sf36QList[4].title }}</div>
      <div class="ans col-12 mb-1">（一直都是 1 ~ 5 從不）</div>
      <template v-for="(q, index) in sf36QList[4].subQues" :key="'q5_' + index">
        <div class="col-12">
          {{ q }}<span class="ans">{{ `${surveyData.ans[4].path[index]}` }}</span>
        </div>
      </template>
      <div class="ans col-12 border-top mt-1 pt-1">（完全沒有妨礙 1 ~ 5 妨礙到極點）</div>
      <div class="col-12">
        {{ sf36QList[5].title }}<span class="ans">{{ `${surveyData.ans[5]}` }}</span>
      </div>
      <div class="ans col-12 border-top mt-1 pt-1">（完全不 1 ~ 6 非常嚴重）</div>
      <div class="col-12">
        {{ sf36QList[6].title }}<span class="ans">{{ `${surveyData.ans[6]}` }}</span>
      </div>
      <div class="ans col-12 border-top mt-1 pt-1">（完全沒有妨礙 1 ~ 5 妨礙到極點）</div>
      <div class="col-12">
        {{ sf36QList[7].title }}<span class="ans">{{ `${surveyData.ans[7]}` }}</span>
      </div>
      <div class="col-12 border-top mt-1 pt-1">{{ sf36QList[8].title }}</div>
      <div class="ans col-12 mb-1">（一直都是 1 ~ 5 從不）</div>
      <template v-for="(q, index) in sf36QList[8].subQues" :key="'q9_' + index">
        <div class="col-12">
          {{ q }}<span class="ans">{{ `${surveyData.ans[8].path[index]}` }}</span>
        </div>
      </template>
      <div class="ans col-12 border-top mt-1 pt-1">（一直都會 1 ~ 5 從不會）</div>
      <div class="col-12">
        {{ sf36QList[9].title }}<span class="ans">{{ `${surveyData.ans[9]}` }}</span>
      </div>
      <div class="col-12 border-top mt-1 pt-1">{{ sf36QList[10].title }}</div>
      <div class="ans col-12 mb-1">（一直都是 1 ~ 5 從不）</div>
      <template v-for="(q, index) in sf36QList[10].subQues" :key="'q11_' + index">
        <div class="col-12">
          {{ q }}<span class="ans">{{ `${surveyData.ans[10].path[index]}` }}</span>
        </div>
      </template>
    </div>
    <div v-if="surveyType === 'sarcf'" class="row fs-6">
      <div class="col-12">
        1. 提起並拿著 10 磅的重物：<span class="ans">{{ getSarcfString(1, surveyData.q1) }}</span>
      </div>
      <div class="col-12">
        2. 在房間走動時：<span class="ans">{{ getSarcfString(2, surveyData.q2) }}</span>
      </div>
      <div class="col-12">
        3. 從椅子/床鋪站起來時：<span class="ans">{{ getSarcfString(3, surveyData.q3) }}</span>
      </div>
      <div class="col-12">
        4. 爬 10 階樓梯：<span class="ans">{{ getSarcfString(4, surveyData.q4) }}</span>
      </div>
      <div class="col-12">
        5. 過去一年，跌倒幾次：<span class="ans">{{ getSarcfString(5, surveyData.q5) }}</span>
      </div>
      <div class="col-12 mt-2">
        總分：<span class="ans">{{ surveyData.total }}</span>
      </div>
    </div>
    <div v-if="surveyType === 'ad8'" class="row fs-6">
      <div class="col-12">
        1. 判斷力上的困難：<span class="ans">{{ getAd8String(surveyData.q1) }}</span>
      </div>
      <div class="col-12">
        2. 對活動和嗜好的興趣降低：<span class="ans">{{ getAd8String(surveyData.q2) }}</span>
      </div>
      <div class="col-12">
        3. 重複相同的問題、故事和陳述：<span class="ans">{{ getAd8String(surveyData.q3) }}</span>
      </div>
      <div class="col-12">
        4. 在學習如何使用工具上有困難：<span class="ans">{{ getAd8String(surveyData.q4) }}</span>
      </div>
      <div class="col-12">
        5. 忘記正確的月份和年份：<span class="ans">{{ getAd8String(surveyData.q5) }}</span>
      </div>
      <div class="col-12">
        6. 處理複雜的財務上有困難：<span class="ans">{{ getAd8String(surveyData.q6) }}</span>
      </div>
      <div class="col-12">
        7. 記住約會的時間有困難：<span class="ans">{{ getAd8String(surveyData.q7) }}</span>
      </div>
      <div class="col-12">
        8. 有持續的思考和技藝方面的問題：<span class="ans">{{ getAd8String(surveyData.q8) }}</span>
      </div>
      <div class="col-12 mt-2">
        總分：<span class="ans">{{ surveyData.total }}</span>
      </div>
    </div>
    <div v-if="surveyType === 'cdr'" class="row fs-6">
      <div class="col-12">
        1. 記憶力：<span class="ans">{{ getCdrString(1, surveyData.memory) }}</span>
      </div>
      <div class="col-12">
        2. 定向感：<span class="ans">{{ getCdrString(2, surveyData.orientation) }}</span>
      </div>
      <div class="col-12">
        3. 解決問題能力：<span class="ans">{{ getCdrString(3, surveyData.solve) }}</span>
      </div>
      <div class="col-12">
        4. 社區活動能力：<span class="ans">{{ getCdrString(4, surveyData.activity) }}</span>
      </div>
      <div class="col-12">
        5. 家居嗜好：<span class="ans">{{ getCdrString(5, surveyData.hobby) }}</span>
      </div>
      <div class="col-12">
        6. 自我照料：<span class="ans">{{ getCdrString(6, surveyData.care) }}</span>
      </div>
    </div>
    <div v-if="surveyType === 'mmse'" class="row fs-6">
      <div class="col-12">
        1. 教育程度：<span class="ans">{{ getMmseString('edu', surveyData.education) }}</span>
      </div>
      <div class="col-12">
        2. 職業：<span class="ans">{{ surveyData.career }}</span>
      </div>
      <div class="col-12">
        3. 界斷分數：<span class="ans">{{ getMmseString('cutoff', surveyData.education) }}</span>
      </div>
      <div class="col-12">
        4. 總分：<span class="ans">{{ surveyData.total }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    surveyType: String,
    surveyData: Object,
  },
  data() {
    return {
      sf36QList: [
        {
          title: '1. 一般來說，您認為您目前的健康狀況是：',
        },
        {
          title: '2. 和一年前比較，您認為您目前的健康狀況是：',
        },
        {
          title:
            '3. 下面是一些您日常可能從事的活動，請問您目前健康狀況會不會限制您從事這些活動？如果會，到底限制有多少？',
          subQues: [
            'a. 費力活動，例如跑步、提重物、參與劇烈運動：',
            'b. 中等程度活動，例如搬桌子、拖地板、打保齡球：',
            'c. 提起或攜帶食品雜貨：',
            'd. 爬數層樓樓梯：',
            'e. 爬一層樓樓梯：',
            'f. 彎腰、跪下、或蹲下：',
            'g. 走路超過1公里：',
            'h. 走數百公尺：',
            'i. 走一百公尺：',
            'j. 自己洗澡或穿衣：',
          ],
        },
        {
          title:
            '4. 在過去四個星期內，您有多少時候因為身體健康問題，而在工作上或其他日常活動方面有下列任何的問題？',
          subQues: [
            'a. 做工作或其他活動的時間減少：',
            'b. 完成的工作量比您想要完成的較少：',
            'c. 可以做的工作或其他活動的種類受到限制：',
            'd. 做工作或其他活動有困難(例如，須更吃力)：',
          ],
        },
        {
          title:
            '5. 在過去四個星期內，您有多少時候因為情緒問題(例如，感覺沮喪或焦慮)，而在工作上或其他日常活動方面有下列的問題？',
          subQues: [
            'a. 做工作或其他活動的時間減少：',
            'b. 完成的工作量比您想要完成的較少：',
            'c. 做工作或其他活動時，沒有平常來的小心：',
          ],
        },
        {
          title:
            '6. 在過去四個星期內，您的健康或情緒問題，對您與家人或朋友、鄰居、社團間的平常活動的妨礙程度如何：',
        },
        {
          title: '7. 在過去四個星期內，您身體疼痛程度有多嚴重：',
        },
        {
          title: '8. 在過去四個星期內，身體疼痛對您的日常工作(包括上半及家務)妨礙程度如何：',
        },
        {
          title:
            '9. 下列各項問題是關於過去四個星期內您的感覺及您對周遭生活的感受，請針對每一問題選一最接近您感覺的答案。在過去四個星期中有多少時候…',
          subQues: [
            'a. 您覺得充滿活力：',
            'b. 您是一個非常緊張的人：',
            'c. 您覺得非常沮喪，沒有任何事情可以讓您高興起來：',
            'd. 您覺得心情平靜：',
            'e. 您精神充沛：',
            'f. 您覺得悶悶不樂和憂鬱：',
            'g. 您覺得筋疲力竭：',
            'h. 您是一個快樂的人：',
            'i. 您覺得累：',
          ],
        },
        {
          title:
            '10. 在過去四星期內，您的身體健康或情緒問題有多少時候會妨礙您的社交活動（如拜訪親友等）：',
        },
        {
          title: '11. 下列各個陳述對您來說有多正確',
          subQues: [
            'a. 我好像比別人較容易生病：',
            'b. 和任何一個我認識的人來比，我和他們一樣健康：',
            'c. 我想我的健康會越來越壞：',
            'd. 我的健康狀況好得很：',
          ],
        },
      ],
    };
  },
  methods: {
    getActivityString(activity) {
      switch (activity) {
        case '':
          return '未填';
        case '1':
          return '臥床';
        case '2':
          return '輕度';
        case '3':
          return '中度';
        case '4':
          return '高度';
        default:
          return 'error';
      }
    },
    getFoisString(level) {
      switch (level) {
        case '1':
          return '等級一：無任何口中進食';
        case '2':
          return '等級二：依靠胃管，但有少量或不一致的口中進食';
        case '3':
          return '等級三：一致的口中進食及胃管，以提供足夠營養';
        case '4':
          return '等級四：完全口中進食，只靠單一質地的食物';
        case '5':
          return '等級五：完全口中進食，可擁有不同質地的食物，但需要特殊處理';
        case '6':
          return '等級六：完全口中進食，不需特殊處理，但需避免特定食物及液體';
        case '7':
          return '等級七：完全口中進食，無任何限制';
        default:
          return 'error';
      }
    },
    getSarcfString(q, a) {
      let str = '';
      if (q === 1 || q === 4) {
        switch (a) {
          case 0:
            str = '0分 - 沒有困難';
            break;
          case 1:
            str = '1分 - 有點困難';
            break;
          case 2:
            str = '2分 - 很困難/無法完成';
            break;
          default:
            str = 'error';
        }
      }
      if (q === 2 || q === 3) {
        switch (a) {
          case 0:
            str = '0分 - 沒有困難';
            break;
          case 1:
            str = '1分 - 有點困難';
            break;
          case 2:
            str = '2分 - 很困難/需要幫忙/無法完成';
            break;
          default:
            str = 'error';
        }
      }
      if (q === 5) {
        switch (a) {
          case 0:
            str = '0分 - 沒有';
            break;
          case 1:
            str = '1分 - 1~3 次';
            break;
          case 2:
            str = '2分 - ≥ 4 次';
            break;
          default:
            str = 'error';
        }
      }
      return str;
    },
    getAd8String(a) {
      switch (a) {
        case 1:
          return '1分 - 是，有改變';
        case 0:
          return '0分 - 不是，沒有改變';
        case 8888:
          return '0分 - 不知道';
        default:
          return 'error';
      }
    },
    getMnasfString(q, a) {
      if (q === 1) {
        switch (a) {
          case 2:
            return '2分 - 食量沒有改變';
          case 1:
            return '1分 - 食量中度減少';
          case 0:
            return '0分 - 食量嚴重減少';
          default:
            return 'error';
        }
      } else if (q === 2) {
        switch (a) {
          case 3:
            return '3分 - 體重沒有下降';
          case 2:
            return `2分 - 體重下降 ${this.surveyData.loseWeight} 公斤`;
          case 1:
            return '1分 - 不知道';
          case 0:
            return `0分 - 體重下降 ${this.surveyData.loseWeight} 公斤`;
          default:
            return 'error';
        }
      } else if (q === 3) {
        switch (a) {
          case 2:
            return '2分 - 食量沒有改變';
          case 1:
            return '1分 - 食量中度減少';
          case 0:
            return '0分 - 食量嚴重減少';
          default:
            return 'error';
        }
      } else if (q === 4) {
        switch (a) {
          case 2:
            return '2分 - 沒有';
          case 0:
            return '0分 - 有';
          default:
            return 'error';
        }
      } else if (q === 5) {
        switch (a) {
          case 2:
            return '2分 - 沒有精神心理問題';
          case 1:
            return '1分 - 輕度失智';
          case 0:
            return '0分 - 嚴重失智或抑鬱';
          default:
            return 'error';
        }
      } else if (q === 6) {
        if (a) {
          let str = `${this.surveyData.bmi}，`;
          switch (a) {
            case 3:
              str += '3分 - 相等或大於 23';
              return str;
            case 2:
              str += '2分 - 21 至低於 23';
              return str;
            case 1:
              str += '1分 - 19 至低於 21';
              return str;
            case 0:
              str += '0分 - 低於 19';
              return str;
            default:
              return 'error';
          }
        } else {
          return '未填';
        }
      } else if (q === 7) {
        if (a) {
          let str = `${this.surveyData.cc}，`;
          switch (a) {
            case 3:
              str += '3分 - 相等或大於 31 公分';
              return str;
            case 0:
              str += '0分 - 低於 31 公分';
              return str;
            default:
              return 'error';
          }
        } else {
          return '未填';
        }
      } else if (q === 8) {
        let str = `${this.surveyData.total}，`;
        if (a > 11) {
          str += '正常營養狀況';
        } else if (a > 7 && a <= 11) {
          str += '有營養不良的風險';
        } else {
          str += '營養不良';
        }
        return str;
      } else {
        return 'error';
      }
    },
    getCdrString(q, a) {
      if (q === 1) {
        switch (a) {
          case 0:
            return '0分 - 沒有記憶力減退或稍微減退。沒有經常性健忘。';
          case 0.5:
            return '0.5分 - 經常性的輕度遺忘，事情只能部分想起；「良性」健忘症。';
          case 1:
            return '1分 - 中度記憶減退；對於最近的事尤其不容易記得；會影響日常生活。';
          case 2:
            return '2分 - 嚴重記憶力減退只有高度重複學過的事務才會記得；新學的東西都很快會忘記。';
          case 3:
            return '3分 - 記憶力嚴重減退只能記得片段。';
          default:
            return 'error';
        }
      } else if (q === 2) {
        switch (a) {
          case 0:
            return '0分 - 完全能定向。';
          case 0.5:
            return '0.5分 - 完全能定向，但涉及時間關聯性時，稍有困難。';
          case 1:
            return '1分 - 涉及時間關聯性時，有中度困難。檢查時，對地點仍有定向力；但在某些場合可能仍有地理定向力的障礙。';
          case 2:
            return '2分 - 涉及時間關聯性時，有嚴重困難；時間及地點都會有定向力的障礙。';
          case 3:
            return '3分 - 只維持對人的定向力。';
          default:
            return 'error';
        }
      } else if (q === 3) {
        switch (a) {
          case 0:
            return '0分 - 日常問題(包括財務及商業性的事物)都能處理的很好；和以前的表現比較，判斷力良好。';
          case 0.5:
            return '0.5分 - 處理問題時，在分析類似性和差異性時，稍有困難。';
          case 1:
            return '1分 - 處理問題時，分析類似性和差異性時，有中度困難；社會價值之判斷力通常還能維持。';
          case 2:
            return '2分 - 處理問題時，分析類似性和差異時有嚴重障礙；社會價值之判斷力通常已受影響。';
          case 3:
            return '3分 - 不能做判斷或解決問題。';
          default:
            return 'error';
        }
      } else if (q === 4) {
        switch (a) {
          case 0:
            return '0分 - 和平常一樣能獨立處理有關、工作、購物、業務、財務、參加義工及社團的事務。';
          case 0.5:
            return '0.5分 - 這些活動稍有障礙。';
          case 1:
            return '1分 - 雖然還能從事有些活動。但無法單獨參與。對一般偶而的檢查，外觀上還似正常。';
          case 2:
            return '2分 - 不會掩飾自己無力獨自處理工作、購物等活動的窘境。被帶出來外面活動時，外觀還似正常。';
          case 3:
            return '3分 - 不會掩飾自己無力獨自處理工作、購物等活動的窘境。外觀上明顯可知病情嚴重，無法在外活動。';
          default:
            return 'error';
        }
      } else if (q === 5) {
        switch (a) {
          case 0:
            return '0分 - 家庭生活，嗜好，知性興趣都維持良好。';
          case 0.5:
            return '0.5分 - 家庭生活，嗜好，知性興趣，稍有障礙。';
          case 1:
            return '1分 - 居家生活確已出現輕度之障礙，較困難之家事已經不做；比較複雜之嗜好及興趣都已放棄。';
          case 2:
            return '2分 - 只有簡單家事還能做興趣很少，也很難維持。';
          case 3:
            return '3分 - 無法做家事。';
          default:
            return 'error';
        }
      } else if (q === 6) {
        switch (a) {
          case 0:
            return '0分 - 能完全自我照料。';
          case 0.5:
            return '0.5分 - 能完全自我照料。';
          case 1:
            return '1分 - 需旁人督促或提醒。';
          case 2:
            return '2分 - 穿衣、個人衛生、及個人事物之料理，都需要幫忙。';
          case 3:
            return '3分 - 個人照料需仰賴別人給予很大的幫忙。經常大小便失禁。';
          default:
            return 'error';
        }
      } else {
        return 'error';
      }
    },
    getMmseString(q, edu) {
      if (q === 'edu') {
        switch (edu) {
          case 1:
            return '未受教育';
          case 2:
            return '國小';
          case 3:
            return '國中以上';
          default:
            return 'error';
        }
      } else if (q === 'cutoff') {
        switch (edu) {
          case 1:
            return '低於16分表示認知功能異常';
          case 2:
            return '低於21分表示認知功能異常';
          case 3:
            return '低於24分為輕度認知功能缺損，低於16分為重度認知功能缺損';
          default:
            return 'error';
        }
      }
      return 'error';
    },
    getDateString(timestamp) {
      const date = new Date(timestamp);
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      let hour = date.getHours();
      let minute = date.getMinutes();
      if (month < 10) {
        month = `0${month}`;
      }
      if (day < 10) {
        day = `0${day}`;
      }
      if (hour < 10) {
        hour = `0${hour}`;
      }
      if (minute < 10) {
        minute = `0${minute}`;
      }
      // 檢測是否為 NaN
      if (Number.isNaN(year)) {
        year = '0000';
      }
      if (Number.isNaN(month)) {
        month = '00';
      }
      if (Number.isNaN(day)) {
        day = '00';
      }
      if (Number.isNaN(hour)) {
        hour = '00';
      }
      if (Number.isNaN(minute)) {
        minute = '00';
      }
      return `${year}年${month}月${day}日 ${hour}:${minute}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.ans {
  color: black;
}
</style>
